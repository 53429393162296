import React, { useState, KeyboardEvent, ChangeEvent } from 'react';
import { Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { graphActions } from 'state/ducks/graph';
import Input from '../BaseComponents/Input';
import Button from '../BaseComponents/Button';
import Container from '../BaseComponents/Container';
import { EXT_LINK_MAX_NAME_LENGTH, EXT_LINK_MAX_URL_LENGTH } from 'config/constants';

interface AddLinkModalProps {
  sourceNode: string;
  modalAnchorElement?: Element;
  onClose: Function;
}

function AddLinkModal(props: AddLinkModalProps) {
  const { sourceNode, modalAnchorElement, onClose } = props;
  const { t } = useTranslation();
  const [linkText, setLinkText] = useState('');
  const [href, setHref] = useState('');

  const dispatch = useDispatch();

  const save = () => {
    if (!!href) {
      dispatch(
        graphActions.addLink({
          url: href,
          displayName: linkText,
          sourceNode,
        }),
      );
      onClose();
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const { code } = event;

    if (code === 'Enter') {
      save();
    }

    if (code === 'Escape') {
      onClose();
    }
  };

  return (
    <Popover open anchorEl={modalAnchorElement}>
      <Container alignItems="stretch" spacing={2} sx={{ minWidth: 288 }}>
        <Typography variant="subtitle2">{t('relations.addMenu.createLink')}</Typography>
        <Input
          placeholder={t('general.richText.linkText')}
          size="large"
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>) => setLinkText(event.target.value)}
          value={linkText}
          onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
          sx={{ mb: 2 }}
          maxLength={EXT_LINK_MAX_NAME_LENGTH}
        />
        <Input
          placeholder={t('general.richText.url')}
          size="large"
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
          value={href}
          onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
          sx={{ mb: 2 }}
          autoFocus
          maxLength={EXT_LINK_MAX_URL_LENGTH}
        />
        <Container direction="row" justifyContent="flex-end" sx={{ pt: 1 }}>
          <Button onClick={() => onClose()}>{t('general.cancel')}</Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={!href}
            onClick={() => {
              save();
            }}
          >
            {t('general.save')}
          </Button>
        </Container>
      </Container>
    </Popover>
  );
}

export default AddLinkModal;
